import React from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';

const CookieBanner = () => {

  const handleAccept = () => {
    // Enable Google Analytics cookies and initialize tracking
    //initializeAndTrack('G-7NRBTFNXJC'); // Replace with your actual Google Analytics tracking ID
    initializeAndTrack('UA-76178787-1'); // Replace with your actual Google Analytics tracking ID
  };

  const handleDecline = () => {
    // Google Analytics will remain disabled since the user declined
    console.log('User declined cookies');
  };

  // Check if consent was given for Google Analytics cookies
  const consentGiven = getCookieConsentValue('gatsby-gdpr-google-analytics');

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      cookieName="gatsby-gdpr-google-analytics"
      enableDeclineButton
      onAccept={handleAccept}
      onDecline={handleDecline}
      style={{
        background: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        borderRadius: '0px',
        padding: '15px',
        fontSize: '14px',
        zIndex: '1000',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}
      buttonStyle={{
        backgroundColor: '#f1c40f',
        border: 'none',
        color: 'black',
        padding: '5px 10px',
        cursor: 'pointer',
        borderRadius: '0px',
        fontSize: '14px',
        marginRight: '60px',
      }}
      declineButtonStyle={{
        backgroundColor: '#f1c40f',
        border: 'none',
        color: 'black',
        padding: '5px 10px',
        cursor: 'pointer',
        borderRadius: '0px',
        fontSize: '14px',
        marginRight: '30px',
      }}
    >
     <p style={{ marginTop: '2.4rem', fontSize: '1.4rem', lineHeight: '1.8rem' }}>
        At Fulcrum GT, we're dedicated to empowering your firm journey. That's why we use cookies to enhance your experience on our site, optimize your interactions, offer social media features, and personalize our marketing efforts. We also use cookies to deliver content and ads tailored to your interest, including on third-party websites. For more information, visit our <a href="/privacy-policy" style={{ color: '#f1c40f', textDecoration: 'none' }}>Privacy Policy</a>.
      </p>
    </CookieConsent>
  );
};

export default CookieBanner;
